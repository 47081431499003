import './WelcomeModal.scss';
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import fbUtilities from '../../../apis/firebase';
import { getParsedTimer } from '../../../helpers';
import Modals from '..';

function WelcomeModal(props) {
  const [bestTime, setBestTime] = useState(-1);
  const [showPolicy, setShowPolicy] = useState(false);
  const [_, setLoading] = useState(true);

  const year = new Date().getFullYear();

  useEffect(() => {
    async function getResult() {
      try {
        const result = await fbUtilities.getTodaysBestResult();
        setBestTime(result?.time ?? -1);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('Something went wrong: ' + error)
      }
    }
    getResult();
  }, [])

  return (
    <>
      <Modal
        show={props.display}
        backdrop="static"
        centered
        className="WelcomeModal"
      >

        <Modal.Header>
          <Modal.Title>
            <h1>SWEDISH DESIGN MEMO</h1>
            <h4>The game</h4>
          </Modal.Title>

        </Modal.Header>
        <Modal.Body>
          <p>
            Collect pairs by marking the squares and turning over two of them.
            The cards automatically flips back if not a pair. When a pair is found,
            the Showroom appears. Mark the winning pair and visit the motif's
            Showroom. Take your time because the clock stops inside the Showroom,
            here players can get important information that can be useful in the quiz
            moment that comes right after the memo game.
          </p>
          <p>
            After completed Memory and Quiz, the result can be submitted for
            participation in the contest. Elapsed time, number of attempts and
            number of correct answers in the Quiz section are all crucial. Send
            in the result. High scores and results are entered automatically from
            everyone who submits their results. To participate, players need to
            complete all steps.
          </p>
          <p className='align-self-center' id='bestTime'>Today's best time: {bestTime < 0 ? '--:--' : getParsedTimer(bestTime)}</p>
          <Button className="playButton" variant="primary" onClick={props.onStart}>Play</Button>

        </Modal.Body>
        <Modal.Footer>
          <p className='d-flex align-items-center justify-content-between w-100'>
            {/* <p><small><img className="designPriceLogoImg" src={designPriceLogo} width="159" height="243" alt="" />Svenska Memos table game "Swedish Design Memo" has been nominated for the 2021's swedish design price. More info at <a href="http://www.svenskamemo.com">www.svenskamemo.com</a></small></p> */}
            <small>
              &copy;Svenska Memo AB {year}{" "} All rights reserved {" "}
              <a href="mailto:info@svenskamemo.se" rel="nofollow">
                info@svenskamemo.se
              </a>
            </small>
            <Button variant={`${props.isCustom ? "outline-info" : "primary"} btn-sm border-0`} onClick={() => setShowPolicy(true)}>Privacy policy</Button>
          </p>

        </Modal.Footer>
      </Modal>
      <Modals.PolicyModal display={showPolicy} onClose={() => setShowPolicy(false)}></Modals.PolicyModal>
    </>
  );
}

export default WelcomeModal;
