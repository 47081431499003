import React from "react";
import PlayableCard from "./PlayableCard";
//import "./Memory.scss";

const PlayableMemory = ({ cardList, onCardClick, pairsPicked }) => {
  const cardsElements = cardList.map((e, k) => {
    return (
      <PlayableCard
        key={k}
        id={e.id}
        idk={k}
        onCardClick={onCardClick}
        flipped={e.flipped}
        paired={
          e.paired ||
          (e.flipped && pairsPicked[0].idk === k && pairsPicked.length === 1)
        }
      />
    );
  });
  return <section className="CardHolder">{cardsElements}</section>;
};

export default PlayableMemory;
