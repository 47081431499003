import { db } from './firebaseConfig';
import {
    collection,
    getDocs,
    query,
    where,
} from "@firebase/firestore";

export const getCustomGame = async (id) => {
    const userGamesQueryResult = await getDocs(
        query(collection(db, "games"), where("id", "==", id))
    );
    const games = [];

    for (const doc of userGamesQueryResult.docs) {
        if (doc.exists()) {
            games.push(doc.data());
        }
    }

    return games;
}