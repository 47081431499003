import React, { useState, useRef } from "react";
import StepIndicator from "Components/StepIndicator/StepIndicator";
import CustomDropdown from "Components/CustomDropdown/CustomDropdown";
import MemoryDisplay from "../../../Components/MemoryDisplay/MemoryDisplay";
import "./CardBack.scss";

import { Button, Form, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import PlusSign from "Assets/Icons/PlusSign";

// import Cardback1 from "./CardBacks/Cardback2.png";
// import Cardback2 from "./CardBacks/Cardback3.png";
// import Cardback3 from "./CardBacks/card_back.png";
import { FiAlignCenter, FiAlignLeft, FiAlignRight } from "react-icons/fi";
import {
  AiOutlineVerticalAlignBottom,
  AiOutlineVerticalAlignMiddle,
  AiOutlineVerticalAlignTop,
} from "react-icons/ai";
import Slider from "rc-slider";
import ImageCrop from "Components/ImageCrop/ImageCrop";

const colorOptions = [
  "#FF65B2",
  "#476257",
  "#54998E",
  "#F0ABB7",
  "#000000",
  "#FFFFFF",
];
const fontOptions = ["Arial", "Times New Roman", "Verdana", "Courier New"];
const textStyles = ["none", "bold", "italic", "underline"];
const verticalAlign = ["top", "middle", "bottom"];
const horizontalAlign = ["start", "center", "end"];

function getStyle(backgroundImage) {
  if (backgroundImage.fontWeight === "bold") return "bold";
  if (backgroundImage.fontStyle === "italic") return "italic";
  if (backgroundImage.textDecoration === "underline") return "underline";
  return "none";
}

const CardBack = ({
  game,
  onNext,
  editGame,
  onPrev,
  goTo,
  draft,
  cardbacks,
  setCardbacks,
}) => {
  // const [cardBacks, setCardBacks] = useState(
  //   getCardbackOptions(game.backgroundImage.backgroundImage)
  // );

  // const [cardBacks, setCardBacks] = useState(
  //   game.backgroundImage.backgroundImage !== ""
  //     ? [Cardback3, Cardback2, Cardback1, game.backgroundImage.backgroundImage]
  //     : [Cardback3, Cardback2, Cardback1]
  // );

  const [selectedImg, setSelectedImg] = useState(
    game.backgroundImage.backgroundImage || ""
  );
  const [selectedColor, setSelectedColor] = useState(
    game.backgroundImage.backgroundColor || ""
  );

  const settingsRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [editImage, setEditImage] = useState(false);

  const closeOpenSettings = (e) => {
    if (
      settingsRef.current &&
      open &&
      !settingsRef.current.contains(e.target)
    ) {
      setOpen(false);
    }
  };

  document.addEventListener("mousedown", closeOpenSettings);

  const [font, setFont] = useState(
    game.backgroundImage.fontFamily || fontOptions[1]
  );
  const [fontSize, setFontSize] = useState(
    parseInt(game.backgroundImage.fontSize) || 1
  );
  const [textStyle, setTextStyle] = useState(getStyle(game.backgroundImage));
  const [textColor, setTextColor] = useState(
    game.backgroundImage.color || colorOptions[1]
  );
  const [textVerticalAlign, setTextVerticalAlign] = useState(
    game.backgroundImage.verticalAlign || verticalAlign[1]
  );
  const [textHorizontalAlign, setTextHorizontalAlign] = useState(
    game.backgroundImage.textAlign || horizontalAlign[1]
  );
  const [text, setText] = useState(game.backgroundImage.backgroundText || "");

  const cardBackgroundStyle = {
    backgroundImage: selectedImg, // cardBack
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: selectedColor,
    backgroundText: text,
    padding: "5px",
    color: textColor,
    fontFamily: font,
    fontSize: `${fontSize}rem`,
    fontWeight: textStyle === "bold" ? "bold" : "normal",
    fontStyle: textStyle === "italic" ? "italic" : "normal",
    textDecoration: textStyle === "underline" ? "underline" : "none",
    textAlign: textHorizontalAlign,
    verticalAlign: textVerticalAlign,
  };

  const onLeaving = () => {
    saveCardBack();
    onNext();
  };

  const toggleSettings = () => {
    setOpen(!open);
  };

  const updateCroppedImage = (croppedImage) => {
    setCardbacks([...cardbacks, croppedImage]);
    setSelectedImg(croppedImage);

    setEditImage(false);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      //setCardbacks([...cardbacks, file]);
      setSelectedImg(file);
      setEditImage(true);
    }
  };

  const resetStyling = () => {
    setSelectedColor("");
    setText("");
    setTextStyle(textStyles[0]);
    setFont(fontOptions[1]);
    setTextColor(colorOptions[1]);
    setTextVerticalAlign(verticalAlign[1]);
    setTextHorizontalAlign(horizontalAlign[1]);
  };

  const handleReset = () => {
    setSelectedImg(cardbacks[0]);
    //setCardBacks([Cardback3, Cardback2, Cardback1]);
    resetStyling();
  };

  const handleColor = (color) => {
    setSelectedColor(color);
    setSelectedImg("");
  };

  const handleImage = (cardBack) => {
    setSelectedImg(cardBack);
    setSelectedColor("");
  };

  const handleNumber = (e) => {
    const value = e.target.value;
    if (value > 3) {
      setFontSize(3);
    } else if (value < 0.25) {
      setFontSize(0.25);
    } else {
      setFontSize(value);
    }
  };

  const saveCardBack = () => {
    editGame({
      ...game,
      backgroundImage: {
        ...game.backgroundImage,
        backgroundImage: selectedImg,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: selectedColor,
        backgroundText: text,
        padding: "5px",
        color: textColor,
        fontFamily: font,
        fontSize: `${fontSize}rem`,
        fontWeight: textStyle === "bold" ? "bold" : "normal",
        fontStyle: textStyle === "italic" ? "italic" : "normal",
        textDecoration: textStyle === "underline" ? "underline" : "none",
        textAlign: textHorizontalAlign,
        verticalAlign: textVerticalAlign,
      },
    });
  };

  return (
    <div className="create-game-layout">
      <div className="display-cardback">
        <p className="back-button" onClick={() => draft(true)}>
          &lt;-- Back to menu
        </p>
        <h1 className="create-game-title">Create your memo</h1>
        {/* Display area */}
        <MemoryDisplay cardStyle={cardBackgroundStyle} />
        <div className="stepbtn-container">
          <StepIndicator currentStep={3} goTo={goTo} />
          <Button
            className="next-btn"
            variant="outline-action"
            onClick={onLeaving}
          >
            Next
          </Button>
        </div>
      </div>

      {/* Sidbar */}
      <nav
        ref={settingsRef}
        className={`cardoptions-nav-container ${open ? "open" : ""}`}
      >
        {/* <div className="backoptions-container"> */}
        <div className="divider-container" onClick={toggleSettings}>
          <div className="divider"></div>
        </div>

        <h2 className="cardoptions-title">Reversed side</h2>

        <div className="color-container">
          <p>Color</p>
          <div className="colors">
            {colorOptions.map((color) => (
              <div
                className={`color-option${
                  selectedColor === color ? " selected" : ""
                }`}
                style={{ backgroundColor: color }}
                onClick={() => {
                  handleColor(color);
                }}
                key={color}
              />
            ))}
          </div>
        </div>

        <div>
          <p>Motif</p>
          <div className="card-container">
            {cardbacks.map((cardBack, i) => (
              <div
                className="cardback-option"
                key={i}
                style={{
                  backgroundImage:
                    cardBack instanceof Blob
                      ? `url(${URL.createObjectURL(cardBack)})`
                      : `url(${cardBack})`,
                  border: `${
                    cardBack === selectedImg ? "1px solid black" : ""
                  }`,
                }}
                onClick={() => {
                  handleImage(cardBack);
                }}
              />
            ))}
            <label className="ctm-uploads">
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageUpload(e)}
                style={{ display: "none" }}
              />
              <PlusSign />
            </label>
          </div>

          <button
            onClick={() => {
              handleReset();
            }}
            className="cardback-button"
          >
            Reset
          </button>
        </div>

        {/* Text */}
        <div className="backtxt-container">
          <h2>Text on the reversed side</h2>
          <div className="backimg-option">
            <textarea
              type="text"
              placeholder="Write your text here"
              className="cardtext-input"
              onChange={(e) => setText(e.target.value)}
            />
          </div>
        </div>
        <div className="options-label-input-container">
          <p className="options-label-item">Color</p>
          <div className="options-input-item colors">
            {colorOptions.map((color) => (
              <div
                className={`color-option${
                  textColor === color ? " selected" : ""
                }`}
                style={{ backgroundColor: color }}
                onClick={() => {
                  setTextColor(color);
                }}
                key={color}
              />
            ))}
          </div>
          <p className="options-label-item">Font</p>
          <div className="options-input-item">
            <CustomDropdown
              options={fontOptions}
              current={font}
              setCurrent={setFont}
              description={"Välj typsnitt"}
              optionFont={"fontFamily"}
              variant={"whiteDropdown"}
            />
          </div>
          <p className="options-label-item">Style</p>
          <div className="options-input-item">
            <CustomDropdown
              options={textStyles}
              current={textStyle}
              description={"Välj textstil"}
              setCurrent={setTextStyle}
              variant={"whiteDropdown"}
            />
          </div>
          <p className="options-label-item">Size</p>
          <div className="options-input-item number-input-container">
            <Form.Control
              type="number"
              value={fontSize}
              step={0.25}
              onChange={handleNumber}
              className="number-control"
            />
            <Slider
              className="number-slider"
              min={0.5}
              max={3}
              step={0.1}
              value={fontSize}
              onChange={setFontSize}
              trackStyle={{ backgroundColor: "#54998E" }}
              handleStyle={{ border: "solid 2px #54998E" }}
            />
          </div>
          <p className="options-label-item">Adjustment</p>
          <div className="options-input-item">
            <ToggleButtonGroup
              value={textHorizontalAlign}
              onChange={setTextHorizontalAlign}
              type="radio"
              name="horizontal"
              className="options-button-group border rounded-lg"
            >
              <ToggleButton
                id="tgb-horizontal-1"
                value={horizontalAlign[0]}
                variant="white"
              >
                {" "}
                <FiAlignLeft />{" "}
              </ToggleButton>
              <ToggleButton
                id="tgb-horizontal-2"
                value={horizontalAlign[1]}
                variant="white"
              >
                {" "}
                <FiAlignCenter />
              </ToggleButton>
              <ToggleButton
                id="tgb-horizontal-3"
                value={horizontalAlign[2]}
                variant="white"
              >
                {" "}
                <FiAlignRight />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          <div className="options-input-item">
            <ToggleButtonGroup
              value={textVerticalAlign}
              onChange={setTextVerticalAlign}
              type="radio"
              name="vertical"
              className="options-button-group border rounded-lg"
            >
              <ToggleButton
                id="tgb-vertical-1"
                value={verticalAlign[0]}
                variant="white"
              >
                {" "}
                <AiOutlineVerticalAlignTop />{" "}
              </ToggleButton>
              <ToggleButton
                id="tgb-vertical-2"
                value={verticalAlign[1]}
                variant="white"
              >
                {" "}
                <AiOutlineVerticalAlignMiddle />
              </ToggleButton>
              <ToggleButton
                id="tgb-vertical-3"
                value={verticalAlign[2]}
                variant="white"
              >
                {" "}
                <AiOutlineVerticalAlignBottom />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        {/* </div> */}
      </nav>
      {editImage && (
        <div className="cropper-container">
          <ImageCrop
            image={selectedImg}
            onExit={setEditImage}
            updateImage={updateCroppedImage}
            required={false}
          />
        </div>
      )}
    </div>
  );
};

export default CardBack;
