import React from "react";
import StepIndicator from "Components/StepIndicator/StepIndicator";
import { Button } from "react-bootstrap";
import "./NameGame.scss";

const NameGame = ({ game, onNext, editGame, goTo, draft }) => {
  return (
    <div className="create-game-layout">
      {/* <div className="gamename-container"> */}
      <p className="back-button" onClick={() => draft(true)}>
        &lt;-- Back to menu
      </p>
      <h1 className="create-game-title">Create your memo</h1>
      <div className="gamename-input">
        <label className="gamename-label">Name your game</label>
        <input
          className="gName-input"
          type="text"
          onChange={(e) => editGame({ ...game, gameName: e.target.value })}
          value={game.gameName}
        />
      </div>
      <div className="stepbtn-container">
        <StepIndicator currentStep={1} goTo={goTo} />
        <Button
          disabled={game.gameName < 1}
          className="next-btn"
          variant="outline-action"
          onClick={onNext}
        >
          Next
        </Button>
      </div>
      <div className="collab-text">
        Svenska Memo i samarbete med Kulturrådet och WeKnowIt
      </div>
      {/* </div> */}
    </div>
  );
};

export default NameGame;
