import React from "react";
import objects from "Assets/SharedImages/bakgrundrosa.png";
import Navbar, { RenderUserInfo } from "Components/Navbar/Navbar";
import "./Overview.scss";

const Overview = () => {
  return (
    <div className={`dash-layout`}>
      <Navbar />
      <main className="content">
        <img className="bg-image" src={objects} alt="objects" />
        {RenderUserInfo("desktop")}
        <section className="cards">
          <h1 className="overview-title">
            Welcome to a new game site depicting topics such as design art and
            culture.
          </h1>
          <p className="overview-p">
            Play our first interactive game Episode 1, Swedish Design Memo. Be
            inspired and create your own game.
          </p>
        </section>
        <span className="collabs">
          Svenska Memo in collaboration with Kulturrådet, Almi and WeKnowIt
        </span>
      </main>
    </div>
  );
};

export default Overview;
//Play our first interactive game Episode 1, Swedish Design Memo.
// Let memories and images become part of your own creativity. Be inspired and create your own game.
