import React from "react";
import "./StepIndicator.scss";

const textForSteps = [
  "Name the game",
  "Choose motifs",
  "Reversed side",
  "Overview",
];

const StepIndicator = ({ currentStep, goTo }) => {
  return (
    <div className="step-container">
      {textForSteps.map((text, i) => (
        <div className="step-wrapper" key={i}>
          <div
            className={`step-style ${currentStep > i ? "active" : ""}`}
            onClick={() => {
              if (currentStep > i) {
                goTo(i);
              }
            }}
          >
            <span className={`step-count ${currentStep > i ? "active" : ""}`}>
              {i + 1}
            </span>
            {i !== textForSteps.length - 1 && (
              <div
                className={`step-line ${currentStep > i + 1 ? "active" : ""}`}
              />
            )}
          </div>
          <div className="steps-label-container">
            <span className="step-label">{text}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StepIndicator;
