import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./ShareLink.scss";
import fbUtilities from "apis/firebase";
import Modals from "..";
const ShareLink = ({ link, display, onClose, fetchGames, isPlayable }) => {
  const [copied, setCopied] = useState(false);
  const [showPolicy, setShowPolicy] = useState(false);

  const handleCopy = () => {
    setPlayable(display);
    navigator.clipboard.writeText(link);
    setCopied(true);
  };

  const handleClose = () => {
    setCopied(false);
    onClose();
  };

  async function handlePlayable(game) {
    await fbUtilities.togglePlayable(game);
    fetchGames();
  }

  function setPlayable(game) {
    if (!isPlayable) {
      handlePlayable(game);
    }
  }

  return (
    <>
      <Modal show={Object.keys(display).length > 0} backdrop="static" centered>
        <Modal.Header
          className="modal-header border-0"
          closeButton
          onHide={handleClose}
        >
          <h1 className="share-link-header">Share your game</h1>
          <p className="information-text">The link is valid for 48h</p>
        </Modal.Header>
        <Modal.Body className="share-link-body">
          <p style={{ lineHeight: 2 }}>
            By sharing your game you guarantee that you follow our Terms and
            Conditions.{" "}
            <strong
              onClick={() => setShowPolicy(true)}
              style={{ cursor: "pointer" }}
            >
              Click to read the Terms and conditions
            </strong>
          </p>
          <Button
            variant={isPlayable ? "primary" : "secondary-outline"}
            className={`playable-button focus`}
            onClick={() => handlePlayable(display)}
          >
            {isPlayable
              ? "The game is playable for a receiver"
              : "Make the game playable for a receiver"}
          </Button>
          {/* {display.playable && (
          <p className="available-text">
            Spelet är tillgängligt för allmänheten
          </p>
        )} */}
          <div className="link-container">
            <div className="share-link-textbox">{link}</div>
            <Button
              variant="copy"
              className={copied ? "active" : ""}
              onClick={handleCopy}
            >
              {copied ? "Copied!" : "Copy link"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modals.PolicyModal
        display={showPolicy}
        onClose={() => setShowPolicy(false)}
      ></Modals.PolicyModal>
    </>
  );
};

export default ShareLink;
