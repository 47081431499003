import React from "react";
import "./DoubleCard.scss";

const DoubleCard = ({ cardImg, index }) => {
  const imageUrl =
    cardImg instanceof Blob ? URL.createObjectURL(cardImg) : cardImg;

  return (
    <>
      <div className="double-wrapper">
        <img src={imageUrl} alt="card-behind" className="back-square" />
        <img src={imageUrl} alt="card" className="square" />
      </div>
      <p className="double-text">Pair {index}</p>
    </>
  );
};

export default DoubleCard;

// <div className="doublecard-container">
//   <div className="double-wrapper">
//     <img src={imageUrl} alt="card-behind" className="back-square" />
//     <img src={imageUrl} alt="card" className="square" />
//   </div>
//   <p className="double-text">Par {index}</p>
// </div>
