import React, { useState } from "react";
import { Formik } from "formik";
import "./Editing.scss";
import Field from "./Field";
import { Button, Form } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "apis/firebase/firebaseConfig";
import { fbUpdateAccount } from "apis/firebase/user";
import toast from "react-hot-toast";

const Account = () => {
  const [user] = useAuthState(auth);
  const [loading, setLoading] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.username) {
      errors.username = "Must choose a username";
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }

    if (values.password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }
    return errors;
  };

  const handleSubmit = async ({ username, email, password }) => {
    setLoading(true);
    fbUpdateAccount(username, email, password)
      .then(() => toast.success("Account details updated!"))
      .catch(() => toast.error("Something went wrong!"))
      .finally(() => setLoading(false));
  };

  const renderField = (props) => <Field {...props} />;

  return (
    <section className="edits-container">
      <h2 className="title">Update Account Details</h2>
      <Formik
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={{
          username: user.displayName,
          email: user.email,
          password: "",
        }}
        initialErrors={
          { password: "Can not be empty" } //not actually rendered, only initialized for disabling button
        }
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            {[
              {
                label: "Username",
                type: "name",
                name: "username",
              },
              {
                label: "E-mail",
                type: "email",
                name: "email",
              },
              {
                label: "Password",
                type: "password",
                name: "password",
              },
            ].map((f) =>
              renderField({
                ...f,
                key: f.label,
                handleChange,
                value: values[f.name],
                touched: touched[f.name],
                errors: errors[f.name],
              })
            )}
            <Button
              disabled={Object.keys(errors).length != 0 || loading}
              variant="primary"
              type="submit"
            >
              {loading ? "Loading..." : "Save"}
            </Button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default Account;
