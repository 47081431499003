import React, { useState } from 'react';
import './Usernaming.scss';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Formik } from 'formik';
import { fbCreateUser } from 'apis/firebase/user';
import toast from 'react-hot-toast';

const Usernaming = ({ onNext, onPrev, user }) => {
  const [loading, setLoading] = useState(false);
  const [authError, setAuthError] = useState('');

  const validate = (values) => {
    if (!values.username) return ({ username: "Please provide a username" })
  }

  //create user & login
  const handleSubmit = async ({ username }) => {
    try {
      setLoading(true);
      const newUser = await fbCreateUser(user.email, user.password, username);
      setLoading(false);
      onNext();
    } catch (error) {
      setLoading(false);
      if (error.code == 'auth/email-already-in-use') {
        setAuthError("Email is already in use!")
      } else {
        setAuthError("Oops!")
      }
    }
  }

  return (
    <main id='layout'>
      <div id='wrapper'>
        <header>
          <h1>Choose a username</h1>
          <span>The username will be displayed in the app</span>
        </header>
        <Formik
          onSubmit={handleSubmit}
          validate={validate}
          initialValues={{ username: '', }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="username">
                <Form.Control
                  type="name"
                  name="username"
                  value={values.username}
                  onChange={handleChange}
                  placeholder="Enter your username"
                  isInvalid={touched.username && errors.username}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.username}
                </Form.Control.Feedback>
              </Form.Group>
              <Button disabled={loading} variant={authError ? 'danger' : 'primary'} type="submit">
                {loading ? 'Loading...' : (authError ? 'Oops!' : 'Create account')}
              </Button>
              {authError}
            </Form>
          )}
        </Formik>
      </div>
      <div className='ctrlBtns'>
        <Button className="back-btn" onClick={onPrev} variant="outline-primary" type="button">
          Go back
        </Button>
      </div>
    </main>
  )
}
export default Usernaming;
