import { useState, useEffect } from 'react';
import cards from "../Assets/data/cards";

export const usePreloadImages = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const preloadImgs = async () => {
      const imgs = cards.map(
        (val) => process.env.PUBLIC_URL + "/imgs/" + val.dirname + "/motiv.png"
      );

      const promises = await imgs.map((src) => {
        return new Promise(function (resolve, reject) {
          const img = new Image();

          img.src = src;
          img.onload = resolve();
          img.onerror = reject();
        });
      });

      await Promise.all(promises);

      setIsLoading(false);
    };

    preloadImgs();
  }, []);

  return isLoading;
}

