import "./GameFinished.scss";
import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Modals from '..'
import { DataContext } from "../../../contexts/DataContext";
import { Link } from "react-router-dom";

function GameFinished(props) {
  const [email, setEmail] = useState('');
  const [showPolicy, setShowPolicy] = useState(false);
  const { state } = useContext(DataContext);
  const year = new Date().getFullYear();
  const getGameResults = () => ({
    time: props.timer,
    quiz: props.quizResult / state.questions.length,
    moves: props.movesCounter,
    email: email,
    //TODO: Generalize game title
    game: "Memory"
  })

  const onSend = () => {
    props.onSendResult(getGameResults());
    setEmail('');
  }

  return (
    <>
      <Modal
        show={props.display}
        backdrop="static"
        keyboard={false}
        centered
        className="GameFinished"
      >
        <Modal.Body>
          {props.quizResult !== null ? (
            <section>
              <h2>Congratulations on finishing the games!</h2>
              <section className="results">
                <div className="result">
                  <p className="resultTitle" style={{ fontSize: "1.4rem" }}> <b>Memo</b></p>
                  <div style={{ display: "flex" }}>
                    <p className="resultScore" >Time: {new Date(props.timer * 1000).toISOString().substr(14, 5)}</p>
                    <p className="resultScore">Moves: {props.movesCounter} </p>
                  </div>
                </div>
                <div className="result">
                  <p className="resultTitle"><b>Quiz</b> </p>
                  <p className="resultScore">
                    {props.quizResult}/{state.questions.length}
                  </p>
                </div>
              </section>
              {!props.isCustom &&
                <Form>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>
                      <b>Enter your email to compete with your result</b>
                      <br />
                      {/* You can win the board game Swedish Design Memo! */}
                    </Form.Label>

                    <Form.Control type='email' value={email} onChange={e => setEmail(e.currentTarget.value)} placeholder='exempel@domain.se' />
                    <small className="form-text text-muted">
                      We'll never share your email with anyone else.
                    </small>
                  </Form.Group>
                </Form>
              }
              <section className="gameFinished-buttons">
                <Button variant={props.isCustom ? "info" : "primary"} onClick={props.onReset}>
                  Play again
                </Button>

                {!props.isCustom &&
                  <Button variant={props.isCustom ? "info" : "primary"} disabled={props.sendResultIsDisabled} onClick={onSend}>Send result</Button>
                }
                <Link to="/">
                  <Button variant={props.isCustom ? "info" : "primary"}>
                    Menu
                  </Button>
                </Link>
              </section>
            </section>
          ) : (
            <section>
              <h2>First part done!</h2>
              <p>Here are your results:</p>
              <p>
                Elapsed time:{" "}
                {new Date(props.timer * 1000).toISOString().substr(14, 5)}{" "}
              </p>
              <p> You can now do the quiz by pressing the button below.</p>
              <section className="buttons">
                {!props.noQuiz &&
                  <Button variant={props.isCustom ? "info" : "primary"} onClick={props.onPlayQuiz}>
                    Play the quiz
                  </Button>
                }
                <Button variant={props.isCustom ? "info" : "primary"} onClick={props.onReset}>
                  Restart
                </Button>
                {props.noQuiz &&
                  <Link to="/">
                    <Button variant={props.isCustom ? "info" : "primary"}>
                      Menu
                    </Button>
                  </Link>
                }
              </section>
            </section>
          )}
        </Modal.Body>
        <Modal.Footer>
          <p className='d-flex align-items-center justify-content-between w-100'>
            <small>
              &copy;Svenska Memo {year} &nbsp;
              <a href="mailto:info@svenskamemo.se" rel="nofollow" style={props.isCustom ? { color: "#374c43" } : {}}>
                info@svenskamemo.se
              </a>
            </small>
            <Button variant={`${props.isCustom ? "outline-info" : "primary"} btn-sm border-0`} onClick={() => setShowPolicy(true)}>Privacy policy</Button>
          </p>
        </Modal.Footer>
      </Modal>
      <Modals.PolicyModal display={showPolicy} onClose={() => setShowPolicy(false)}></Modals.PolicyModal>
    </>
  );
}

export default GameFinished;
