import { auth } from 'apis/firebase/firebaseConfig';
import { leaderboard } from 'apis/firebase/results';
import React, { useState, useEffect } from 'react'
import { Modal } from "react-bootstrap";
import { useAuthState } from 'react-firebase-hooks/auth';
import './Leaderboard.scss'

const Leaderboard = ({ title, game, display, onClose }) => {
  const [scores, setScores] = useState([]);
  const [user] = useAuthState(auth);

  useEffect(async () => {
    const tops = await leaderboard(game, 10);
    setScores(tops);
  }, [display])

  return (
    <Modal
      show={display}
      backdrop="static"
      centered
    >
      <Modal.Header className="modal-header border-0" closeButton onHide={onClose}>
        <h1 style={{ textAlign: 'center', width: '100%' }}>
          {title}
        </h1>
      </Modal.Header>
      <Modal.Body>
        <table>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Name</th>
              <th>Time</th>
              <th>Moves</th>
              <th>Quiz</th>
            </tr>
          </thead>
          <tbody>
            {scores.map((d, i) => (
              <tr className={i % 2 == 0 ? 'bg-light' : ''} key={d.time + d.quiz + d.moves}>
                <td>{i + 1}</td>
                <td>{user.displayName}</td>
                <td>{d.time}</td>
                <td>{d.moves}</td>
                <td>{Math.round(d.quiz * 100)}%</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
    </Modal >

  )
}

export default Leaderboard
