import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import AuthView from "../AuthView/AuthView.jsx";
import { Formik } from 'formik';

import React, { useState } from 'react'
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from 'apis/firebase/firebaseConfig.js';
import { useNavigate } from 'react-router-dom';

const question = "Don't have an account yet? Create one";

const Login = () => {
  const [authError, setAuthError] = useState('');
  const navigate = useNavigate();

  //login
  const handleSubmit = async (values) => {
    signInWithEmailAndPassword(auth, values.id, values.password)
      .then(() => {
        setAuthError('');
        navigate('/dashboard');
      })
      .catch((error) => {
        switch (error.code) {
          case 'auth/invalid-email':
            setAuthError("Invalid email address!");
            break;
          case 'auth/wrong-password':
            setAuthError("Wrong password!");
            break;
        }
      });
  }

  const validate = (values) => {
    const errors = {};

    if (!values.id) {
      errors.id = "Please enter your credentials"
    }

    if (!values.password) {
      errors.password = "Please enter your password"
    }

    return errors;
  }

  return (
    <AuthView title="Log in" subtitle="Log into your account to play" question={question} to="/new">
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ id: '', password: '' }}
        validate={validate}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit} >
            <Form.Group className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="name"
                name="id"
                onChange={handleChange}
                value={values.id}
                isInvalid={touched.id && errors.id || authError}
                placeholder="Enter email" />
              <Form.Control.Feedback type="invalid">
                {authError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                onChange={handleChange}
                value={values.password}
                isInvalid={touched.password && errors.password}
                placeholder="Password" />
            </Form.Group >
            <Button variant="primary" type="submit">
              Log in
            </Button>
          </Form >
        )}
      </Formik>
    </AuthView >
  )
}

export default Login


