import React from "react";
import Card from "./GameCards";
import objects from "Assets/SharedImages/bakgrundrosa.png";
import Navbar, { RenderUserInfo } from "Components/Navbar/Navbar";
import "./DashBoard.scss";

const Dashboard = () => {
  return (
    <div className={`dash-layout`}>
      <Navbar />
      <main className="content">
        <img className="bg-image" src={objects} alt="objects" />
        {RenderUserInfo("desktop")}
        <section className="cards">
          <h1 className="games-title">Svenska Memo Game Collection</h1>
          <Card.Memo />
        </section>
        <span className="collabs">
          Svenska Memo in collaboration with Kulturrådet, Almi and WeKnowIt
        </span>
      </main>
    </div>
  );
};

export default Dashboard;
