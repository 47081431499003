import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { ArrowRight } from 'react-bootstrap-icons';

import ImageCarousel from '../ImageCarousel/ImageCarousel';
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { DataContext } from '../../contexts/DataContext';

function CustomSideInfo(props) {
  const { state } = useContext(DataContext)
  return (
    <div className={"SideInfo custom"}>

      <SwitchTransition mode="out-in">
        <CSSTransition
          timeout={300}
          key={props.selectedShowRoom}
          classNames="fade"
        >
          <div>
            {
              props.selectedShowRoom != null ?
                <div>
                  <div className={`infoHolderBig custom ${props.selectedShowRoom != null ? "" : "hide"}`}>
                    <div className="showroomText">
                      Showroom
                    </div>
                    <img src={props.game.gameImages[props.selectedShowRoom]} alt={props.game.showroom[props.selectedShowRoom].name} />

                    <h3>{props.game.showroom[props.selectedShowRoom].imageTitle}</h3>
                    <div id='cardStack' className='mb-2'>
                      <div onClick={props.onShowObjectsClick} id='stackWrapper'>
                        <img className='savedCard' src={props.game.backgroundImage.backgroundColor !== "" ? process.env.PUBLIC_URL + '/imgs/card_back.png' : props.game.backgroundImage.backgroundImage} alt='Card back' />
                        <img className='savedCard rotated' src={props.game.backgroundImage.backgroundColor !== "" ? process.env.PUBLIC_URL + '/imgs/card_back.png' : props.game.backgroundImage.backgroundImage} alt='Card back' />
                        <p id='nbrFounds'>{state.founds.length}</p>
                      </div>
                    </div>
                    <div className="readMoreWrapper">
                      <Button
                        id="buttonReadMoreBig"
                        block
                        rel="nofollow"
                        onClick={props.onOpenShowroom}
                        variant="info"
                      >
                        Read more <ArrowRight className="arrowRight" size={19} />
                      </Button>
                    </div>
                  </div>
                  <div className="infoHolderSmall">
                    <img
                      src={props.game.gameImages[props.selectedShowRoom]}
                      alt={props.game.showroom[props.selectedShowRoom].name}
                    />
                    <Button
                      id="buttonReadMoreSmall"
                      variant="info"
                      block
                      rel="nofollow"
                      className="custom"
                      onClick={props.onOpenShowroom}>
                      Read more about {props.game.showroom[props.selectedShowRoom].imageTitle}
                      <ArrowRight className="arrowRight" size={24} />
                    </Button>

                  </div>
                </div>

                : ""
            }{
              props.showObjects && (
                <div className={`infoHolderBig custom ${props.showObjects != null ? "" : "hide"}`}>
                  <ImageCarousel items={3} onClick={props.onSavedObjectClick} />
                </div>
              )
            }
          </div>
        </CSSTransition>
      </SwitchTransition>

    </div >
  );
}

export default CustomSideInfo;
