import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import fbUtilities from "apis/firebase";
import { Button, Form } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import { ImArrowLeft2, ImArrowRight2 } from "react-icons/im";
import QuizExitModal from "Components/Modals/QuizExitModal/QuizExitModal";
import "./CreateShowroom.scss";

/**
 * Initial showroom used when no showroom exists previously
 */
const emptyShowroom = {
  imageTitle: "",
  imageDescription: "",
  imageOption1: "",
  imageOption2: "",
  imageOption3: "",
  imageOption4: "",
};

const CreateShowroom = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [toggleExitModal, setToggleExitModal] = useState(false);

  const initShowroom = () => {
    if (state.game.showroom !== undefined) {
      const descriptions = images.map((img) => {
        return { ...emptyShowroom, image: img };
      });

      const res = descriptions.map((desc) => {
        const exists = state.game.showroom.find((r) => r.image === desc.image);
        if (exists) {
          return exists;
        }
        return desc;
      });
      return res;
    }
    return images.map((img) => {
      return { ...emptyShowroom, image: img };
    });
  };

  const images = state.game.gameImages;
  const [imageDescriptions, setImageDescriptions] = useState(initShowroom);

  const [currentImage, setCurrentImage] = useState(0);

  const [imageTitle, setImageTitle] = useState("");

  const [toggleDesc, setToggleDesc] = useState(false);
  const [imageDescription, setImageDescription] = useState("");
  const [toggleOptional1, setToggleOptional1] = useState(false);
  const [optional1, setOptional1] = useState("");
  const [toggleOptional2, setToggleOptional2] = useState(false);
  const [optional2, setOptional2] = useState("");
  const [toggleOptional3, setToggleOptional3] = useState(false);
  const [optional3, setOptional3] = useState("");
  const [toggleOptional4, setToggleOptional4] = useState(false);
  const [optional4, setOptional4] = useState("");

  useEffect(() => {
    const current = imageDescriptions[currentImage];
    setImageTitle(current.imageTitle);
    setToggleDesc(current.imageDescription !== "");
    setImageDescription(current.imageDescription);
    setToggleOptional1(current.imageOption1 !== "");
    setOptional1(current.imageOption1);
    setToggleOptional2(current.imageOption2 !== "");
    setOptional2(current.imageOption2);
    setToggleOptional3(current.imageOption3 !== "");
    setOptional3(current.imageOption3);
    setToggleOptional4(current.imageOption4 !== "");
    setOptional4(current.imageOption4);
  }, [imageDescriptions, currentImage]);

  const changeImage = (i) => {
    const updated = {
      imageTitle: imageTitle,
      imageDescription: imageDescription,
      imageOption1: optional1,
      imageOption2: optional2,
      imageOption3: optional3,
      imageOption4: optional4,
      image: imageDescriptions[currentImage].image,
    };
    imageDescriptions[currentImage] = updated;
    setImageDescriptions(imageDescriptions);
    setCurrentImage(i);
  };

  const handleSave = async () => {
    const updated = {
      imageTitle: imageTitle,
      imageDescription: imageDescription,
      imageOption1: optional1,
      imageOption2: optional2,
      imageOption3: optional3,
      imageOption4: optional4,
      image: imageDescriptions[currentImage].image,
    };
    imageDescriptions[currentImage] = updated;
    await fbUtilities.updateShowroom({
      ...state.game,
      showroom: imageDescriptions,
    });
    navigate("/mygames");
  };

  return (
    <div className="create-showroom-layout">
      <div className="showroom-display">
        <p className="back-button" onClick={() => setToggleExitModal(true)}>
          &lt;-- Back to menu
        </p>
        <div className="title-container">
          <ImArrowLeft2
            className={`arrow ${currentImage !== 0 ? "available" : ""}`}
            size={30}
            onClick={() => changeImage(Math.max(currentImage - 1, 0))}
          />
          <h1 className="create-showroom-title">Create your showroom</h1>
          <p className="create-showroom-title-mobile">
            Pair {currentImage + 1}
          </p>
          <ImArrowRight2
            className={`arrow ${
              currentImage !== images.length - 1 ? "available" : ""
            }`}
            size={30}
            onClick={() =>
              changeImage(Math.min(currentImage + 1, images.length - 1))
            }
          />
        </div>
        <img
          src={images[currentImage]}
          alt="current-pair"
          className="showroom-image"
        />
        {/* Display area */}
        <Form className="create-showroom-container">
          <Form.Control
            className="showroom-title-input"
            type="text"
            placeholder="Name your motif"
            value={imageTitle}
            onChange={(e) => setImageTitle(e.target.value)}
          />
          {toggleDesc ? (
            <textarea
              className="showroom-description-input"
              placeholder="Add a description"
              value={imageDescription}
              onChange={(e) => setImageDescription(e.target.value)}
            />
          ) : (
            <Button
              variant="description"
              className="showroom-description-button"
              onClick={() => setToggleDesc(true)}
            >
              Add a description <AiOutlinePlus />
            </Button>
          )}
          <div className="showroom-divider"></div>
          <span className="showroom-snippet">
            Complete texts within different categories as an option, for example
            year, artist, place, etc. These fields can also be left blank
          </span>
          {toggleOptional1 ? (
            <textarea
              className="showroom-optional-input optional1"
              placeholder="Optional 1"
              value={optional1}
              onChange={(e) => setOptional1(e.target.value)}
            />
          ) : (
            <Button
              variant="description"
              className="showroom-optional-button optional1"
              onClick={() => setToggleOptional1(true)}
            >
              Title and description
            </Button>
          )}
          {toggleOptional2 ? (
            <textarea
              className="showroom-optional-input optional2"
              placeholder="Optional 2"
              value={optional2}
              onChange={(e) => setOptional2(e.target.value)}
            />
          ) : (
            <Button
              variant="description"
              className="showroom-optional-button optional2"
              onClick={() => setToggleOptional2(true)}
            >
              Title and description
            </Button>
          )}
          {toggleOptional3 ? (
            <textarea
              className="showroom-optional-input optional3"
              placeholder="Optional 3"
              value={optional3}
              onChange={(e) => setOptional3(e.target.value)}
            />
          ) : (
            <Button
              variant="description"
              className="showroom-optional-button optional3"
              onClick={() => setToggleOptional3(true)}
            >
              Title and description
            </Button>
          )}
          {toggleOptional4 ? (
            <textarea
              className="showroom-optional-input optional4"
              placeholder="Optional 4"
              value={optional4}
              onChange={(e) => setOptional4(e.target.value)}
            />
          ) : (
            <Button
              variant="description"
              className="showroom-optional-button optional4"
              onClick={() => setToggleOptional4(true)}
            >
              Title and description
            </Button>
          )}
          <Button
            variant="description"
            className="showroom-next"
            onClick={() =>
              changeImage(
                currentImage === images.length - 1 ? 0 : currentImage + 1
              )
            }
          >
            Next pair
          </Button>
        </Form>
      </div>

      {/* Sidbar */}
      <nav className={`showroom-nav-container`}>
        <div className="images-container">
          {images.map((q, i) => {
            return (
              <Button
                variant="image"
                key={i}
                className={`image-button ${
                  currentImage === i ? "current" : ""
                }`}
                onClick={() => changeImage(i)}
              >
                <img src={q} alt="pair" className="button-image" />
                <span
                  className={`outer-circle ${
                    currentImage === i ? "current" : ""
                  }`}
                >
                  <span
                    className={`inner-circle ${
                      currentImage === i ? "current" : ""
                    }`}
                  ></span>
                </span>
                <span
                  className={`pair-nbr ${currentImage === i ? "current" : ""}`}
                >
                  Pair {i + 1}
                </span>
              </Button>
            );
          })}
        </div>
        <Button
          variant="green"
          className="add-showroom"
          onClick={() => handleSave()}
        >
          Save showroom
        </Button>
      </nav>
      {toggleExitModal && (
        <QuizExitModal
          setToggleExitModal={setToggleExitModal}
          saveQuiz={handleSave}
        />
      )}
    </div>
  );
};

export default CreateShowroom;
