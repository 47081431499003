import { formatPrice } from 'apis/firebase/stripe/subscriptions';
import React from 'react'
import { Button, Card, Form } from 'react-bootstrap';
import './Plan.scss';

const Plan = ({ plan, checked, onChange, disabled }) => {

  const renderPrice = () => {
    if (plan.expired) {
      return 'EXPIRED'
    } else if (plan.price == 0) {
      return "FREE!"
    } else {
      return formatPrice(plan.price);
    }
  }

  const cardClass = () => {
    if (plan.expired || disabled) {
      return 'expired'
    } else if (checked) {
      return 'active'
    } else {
      return ''
    }
  }

  return (
    <Card onClick={_ => onChange(plan)} className={cardClass()}>
      <Card.Header>{plan.title}</Card.Header>
      <Card.Body>
        <Card.Title>
          {renderPrice()}
          <Form.Check
            type="radio"
            name="picked"
            value={plan}
            checked={checked}
            onChange={_ => onChange(plan)}
          />
        </Card.Title>
        <Card.Text>{plan.description}</Card.Text>
      </Card.Body>
    </Card>
  )
}

export default Plan
