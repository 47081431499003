import React from "react";
import "./Landing.scss";
import memo from "./memo.png";
import phone from "./man-holding-phone.png";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FiExternalLink } from "react-icons/fi";

const Landing = () => {
  const navigate = useNavigate();

  return (
    <div className="landingContainer">
      <img className="memo-logo" src={memo} alt="Logo" />
      <img className="phone" src={phone} alt="Man with phone" />
      <main className="info">
        <h1>design meets game</h1>
        <p>
          Challenge yourself or a friend. Play our designgame, and make your own
          game with premium access.
        </p>
        <div className="btns">
          <a
            href={"https://svenskamemo.com/om-svenska-memo"}
            target="_blank"
            rel="noreferrer"
          >
            <Button className="px-4 m-0" type="button" variant="secondary">
              About us
            </Button>
          </a>
          <Button
            onClick={() => navigate("new")}
            className="px-4 m-0"
            type="button"
            variant="secondary"
          >
            Sign up
          </Button>
          <Button
            onClick={() => navigate("login")}
            className="px-4 m-0"
            type="button"
            variant="outline-secondary"
          >
            Log in
          </Button>
        </div>
        <small>This site is under beta testing</small>
        <span className="read-more">
          Read more at{" "}
          <a target="_blank" href="https://www.svenskamemo.se">
            svenskamemo.se <FiExternalLink size={13} />
          </a>
        </span>
        <span className="rights">
          ©Svenska Memo AB 2023 All rights reserved
        </span>
      </main>
      <span className="collabs">
        Svenska Memo in collaboration with Kulturrådet, Almi and WeKnowIt
      </span>
      <span className="offer">
        For new subscribers take advantage of a free trial
      </span>
    </div>
  );
};

export default Landing;
