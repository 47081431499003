import React, { useState, useEffect, useCallback } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import "./MyGames.scss";
import Navbar, { RenderUserInfo } from "Components/Navbar/Navbar";
import Modals from "Components/Modals";
import fbUtilities from "apis/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "apis/firebase/firebaseConfig";
import GamesDisplay from "Components/GamesDisplay/GamesDisplay";
import { getClaimRole } from "apis/firebase/stripe/subscriptions";

/**
 *  Mygames presents the users published and unpublished games.
 *  It fetches the games from firebase and allows for editing and
 *  creating new games.
 *
 *  You can play the game by clicking the name, or by pasting the
 *  path found in share.
 *
 * @returns Mygames page
 */

const MyGames = () => {
  const navigate = useNavigate();
  const [shareDisplay, setShareDisplay] = useState({});
  //const [createDisplay, setCreateDisplay] = useState(false);
  const [user] = useAuthState(auth);
  const [publishedGames, setPublishedGames] = useState([]);
  const [unpublishedGames, setUnpublishedGames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLinkPlayable, setIsLinkPlayable] = useState(false);
  const [role, setRole] = useState("");

  useEffect(() => {
    async function getRole() {
      const role = await getClaimRole();
      setRole(role);
    }
    getRole();
  }, []);

  const fetchGames = useCallback(async () => {
    try {
      const games = await fbUtilities.getUserGames(user.uid);
      const unpub = games
        .filter((g) => g.isDraft)
        .sort((a, b) =>
          a.gameName.toUpperCase().localeCompare(b.gameName.toUpperCase())
        );
      setUnpublishedGames(unpub);
      const pub = games
        .filter((g) => !g.isDraft)
        .sort((a, b) =>
          a.gameName.toUpperCase().localeCompare(b.gameName.toUpperCase())
        );

      console.log(pub);
      setPublishedGames(pub);
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  }, [user]);

  useEffect(() => {
    if (Object.keys(shareDisplay).length > 0) {
      const current = publishedGames.find((g) => g.id === shareDisplay.id);
      setShareDisplay(current);
      setIsLinkPlayable(current.playable);
    }
  }, [shareDisplay, publishedGames]);

  const handleDelete = async (game) => {
    setIsLoading(true);
    await fbUtilities.deleteGame(game);
    fetchGames();
  };

  const handleEdit = (game, type) => {
    switch (type) {
      case "Memory":
        navigate("/creategame", { state: { game: game } });
        break;
      case "Quiz":
        navigate("/createquiz", { state: { game: game } });
        break;
      case "Showroom":
        navigate("/createshowroom", { state: { game: game } });
        break;
      default:
        break;
    }
  };

  const handleAdd = (game, type) => {
    switch (type) {
      case "Memory":
        navigate("/creategame", { state: {} });
        break;
      case "Quiz":
        navigate("/createquiz", { state: { game: game } });
        break;
      case "Showroom":
        navigate("/createshowroom", { state: { game: game } });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    fetchGames();
  }, [fetchGames]);

  return (
    <div className={`dash-custom-layout`}>
      <Navbar />
      <main className="content">
        {RenderUserInfo("desktop")}
        <div className="my-games-container">
          <div>
            <p className="my-games-title">My games</p>
            <Link
              to="/creategame"
              className={`my-games-button ${
                role !== "premium" ? "disabled" : ""
              }`}
            >
              <Button
                variant="green"
                className="my-games-button"
                //onClick={() => setCreateDisplay(true)}
              >
                <AiOutlinePlus className="plus-icon" /> Create new game
              </Button>
            </Link>
          </div>
          <div>
            <Tabs defaultActiveKey={"published"} id="my-games-tab">
              <Tab eventKey={"published"} title="Published">
                {publishedGames.length > 0 ? (
                  <GamesDisplay
                    games={publishedGames}
                    isLoading={isLoading}
                    setDisplay={setShareDisplay}
                    setIsLinkPlayable={setIsLinkPlayable}
                    handleAdd={handleAdd}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    fetchGames={fetchGames}
                    isPublished={true}
                  />
                ) : (
                  <div>
                    <p className="noGamesText">
                      Click on "Create new game" to start filling out this space
                      with your games.
                    </p>
                  </div>
                )}
              </Tab>
              <Tab eventKey={"archive"} title="Unpublished">
                {unpublishedGames.length > 0 ? (
                  <GamesDisplay
                    games={unpublishedGames}
                    isLoading={isLoading}
                    setDisplay={setShareDisplay}
                    setIsLinkPlayable={setIsLinkPlayable}
                    handleAdd={handleAdd}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    fetchGames={fetchGames}
                    isPublished={false}
                  />
                ) : (
                  <div>
                    <p className="noGamesText">
                      Här finns inga spel just nu - klicka på "skapa nytt spel"
                      för att skapa ett.
                    </p>
                  </div>
                )}
              </Tab>
            </Tabs>
          </div>
          <Modals.ShareLink
            link={`${window.location.href.slice(0, -7)}custom/${
              shareDisplay.id
            }`}
            display={shareDisplay}
            onClose={() => setShareDisplay("")}
            fetchGames={fetchGames}
            isPlayable={isLinkPlayable}
          />
        </div>
      </main>
      {/* <Modals.CreateGameModal
        display={createDisplay}
        onClose={() => setCreateDisplay(false)}
      /> */}
    </div>
  );
};

export default MyGames;
