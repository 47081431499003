import React, { useState } from 'react'
import { Modal, Button } from "react-bootstrap";
import LearnMoreButton from '../../Buttons/ReadMoreButton';
import './PlayModal.scss'

const PlayModal = ({ onPlay }) => {
  const [hasVisited, _] = useState(() => {
    const visited = JSON.parse(localStorage.getItem('visited'));
    if (!visited) {
      localStorage.setItem('visited', true);
    }
    return visited;
  });

  return (
    <Modal
      show
      backdrop={false}
      centered
    >
      <Modal.Header className="modal-header border-0" onHide={() => console.log("yo")}>
        <Modal.Title className='font-weight-bold d-flex flex-column'>
          DESIGN MEETS GAME
          {!hasVisited &&
            <span className='font-weight-normal'>
              Welcome to an interactive entertainment
            </span>
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <strong>We're now open!</strong>
        <br />
        <p>
          Good luck!
        </p>
        <Modal.Footer style={{ gap: 20 }} className="modal-header border-0 p-0 justify-content-start">
          <div className='buttons'>
            <Button onClick={onPlay} className='px-4 m-0' type="button" variant="primary" >
              Play
            </Button>
            <LearnMoreButton />
          </div>
          <small>&copy; 2022 Svenska Memo in cooperation with We Know IT, supported by Swedish Arts Council and Almi</small>
        </Modal.Footer>
      </Modal.Body>
    </Modal >
  )
}

export default PlayModal
