import './ImageCarousel.scss'
import React, { useContext, useEffect } from "react";
import Carousel from 'react-bootstrap/Carousel'
import { DataContext } from "../../contexts/DataContext";

const groupByN = (arr, size) =>
  arr.length > size
    ? [arr.slice(0, size), ...groupByN(arr.slice(size), size)]
    : [arr];

const ImageCarousel = ({ onClick, items }) => {
  const MAX_PICS = items;
  const { state } = useContext(DataContext);
  const groupedFounds = groupByN(state.founds, MAX_PICS);

  return (
    <Carousel controls={groupedFounds.length > 1} indicators={false} interval={null}>
      {groupedFounds.map(cardArray => (
        <Carousel.Item key={cardArray.toString()}>
          <div className='item'>
            {cardArray.map(info => {
              const parsed = JSON.parse(info);
              return (
                <img
                  key={parsed.id}
                  onClick={onClick(parsed.id)}
                  className='img'
                  src={parsed.custom ? parsed.dirname : process.env.PUBLIC_URL + "/imgs/" + parsed.dirname + "/motiv.png"}
                  alt={parsed.dirname}
                />
              )
            })}
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

export default ImageCarousel;
