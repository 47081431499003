import React from "react";
import { useNavigate } from "react-router-dom";
import GameCard from "./GameCard";

const MemoCard = () => {
  const navigate = useNavigate();
  return (
    <GameCard
      onPlay={() => navigate("/games/memory")}
      src={process.env.PUBLIC_URL + "/imgs/card_back.png"}
      title="SWEDISH DESIGN MEMO"
      leaderboardTitle="Design Memo"
      description={`Illuminates Swedish design along a timeline from 1845 until today. The game is not only a memo game
                    it also works as a quiz. Complete all steps to participate in the Top Score list. 
      `}
      optional={`The list is updated here continously. The game is a tribute to Swedish design.`}
      disclaimer={`
Image on the reverse side of the playing, Zibidie 2005, Designer Tom Hedqvist, (10-gruppen) since 2015 Ikea of Sweden© Ikea Systems B.V. 2015
      `}
      game="memory"
    />
  );
};

export default MemoCard;

// illuminates Swedish design along a timeline from 1845 to today.
// This game is not only a memorygame. It also works as a quiz. The questions can be an ex-
// citing bonus for those eager to test their design expertise. Players who complete all stages
// can get the opportunity to participate in the top score list, which is updated continuously.

// Image on the reverse side of the playing card, Zibidie 2005, Designer Tom Hedqvist, ursprungligen
// 10-gruppen, sedan 2015 Ikea of Sweden © IKEA Systems B.V. 2015
