import WelcomeModal from './WelcomeModal/WelcomeModal';
import AreYouSureModal from './AreYouSureModal/AreYouSureModal';
import FeedbackModal from './FeedbackModal/FeedbackModal';
import GameFinished from './GameFinished/GameFinished';
import PlayModal from './PlayModal/PlayModal';
import PolicyModal from './PolicyModal/PolicyModal';
import BookfairModal from './BookfairModal/BookfairModal';
import LoginModal from './LoginModal/LoginModal';
import Leaderboard from './Leaderboard/Leaderboard';
import ShareLink from './ShareLinkModal/ShareLink';
import CreateGameModal from './CreateGameModal/CreateGameModal';
import CustomWelcomeModal from './CustomWelcomeModal/CustomWelcomeModal';

const Modals = {
  Bookfair: BookfairModal,
  WelcomeModal,
  AreYouSureModal,
  FeedbackModal,
  GameFinished,
  PlayModal,
  PolicyModal,
  LoginModal,
  Leaderboard,
  ShareLink,
  CreateGameModal,
  CustomWelcomeModal,
}

export default Modals;
