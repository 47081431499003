import { currentSubscription, getClaimRole } from 'apis/firebase/stripe/subscriptions';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import './Editing.scss';

const Subscription = () => {
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState(null)

  useEffect(async () => {
    const role = await getClaimRole();
    setRole(role);
    setLoading(false);
  }, [])

  return (
    !loading &&
    (
      <section className='edits-container'>
        <h2 className="title">Subscription</h2>
        <p>Current plan</p>
        <p className="tier-container">{role ?? 'N/A'}
          <Link to="/subscription">Manage</Link>
        </p>
        <hr />
        <p>To see your next billing, change your payment information or cancel your subscription, please visit the <Link to="/subscription">billing portal</Link>.</p>
        <hr />
      </section>
    )
  )
}

export default Subscription
