import React from "react";

const MemoryIcon = ({ className }) => {
    return (
        <svg
            // width="25"
            // height="25"
            viewBox="0 0 26 26"
            fill="none"
            className={className}
            xmlns="http://www.w3.org/2000/svg">
            <rect width="11.5385" height="11.5385" rx="1" />
            <rect y="13.4615" width="11.5385" height="11.5385" rx="1" />
            <rect x="13.4615" width="11.5385" height="11.5385" rx="1" />
            <rect x="13.4615" y="13.4615" width="11.5385" height="11.5385" rx="1" />
        </svg>
    )
}

export default MemoryIcon;