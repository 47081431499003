import React from "react";

const CropImg = ({ className, onClick }) => {
  return (
    <svg
      // width="70%"
      // height="70%"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}>
      <path
        d="M27.125 6.61777L30.4309 3.31191C31.1877 2.55508 31.1877 1.32598 30.4309 0.569141C29.674 -0.187695 28.4449 -0.187695 27.6881 0.569141L24.3822 3.875H9.6875V7.75H20.5072L7.75 20.5072V1.9375C7.75 0.86582 6.88418 0 5.8125 0C4.74082 0 3.875 0.86582 3.875 1.9375V3.875H1.9375C0.86582 3.875 0 4.74082 0 5.8125C0 6.88418 0.86582 7.75 1.9375 7.75H3.875V23.25C3.875 25.3873 5.6127 27.125 7.75 27.125H21.3125V23.25H10.4928L23.25 10.4928V29.0625C23.25 30.1342 24.1158 31 25.1875 31C26.2592 31 27.125 30.1342 27.125 29.0625V27.125H29.0625C30.1342 27.125 31 26.2592 31 25.1875C31 24.1158 30.1342 23.25 29.0625 23.25H27.125V6.61777Z"
      />
    </svg>
  );
};

export default CropImg;
