export const dataActions = {
  UPDATE_FOUNDS: 'UPDATE_FOUNDS',
  SET_QUESTIONS: 'SET_QUESTIONS',
  RESET_FOUNDS: 'RESET_FOUNDS'
}

export const dataReducer = (state, action) => {
  switch (action.type) {
    case dataActions.UPDATE_FOUNDS:
      // THIS WAS used to save objects *between* sessions.
      // const savedObjects = JSON.parse(localStorage.getItem('founds')) ?? [];
      // const foundCard = action.payload;

      // if (!savedObjects.includes(foundCard)) {
      //   const newFounds = [...savedObjects, foundCard];
      //   // update localStorage
      //   const jsonFounds = JSON.stringify(newFounds);
      //   localStorage.setItem('founds', jsonFounds);

      //   return {
      //     ...state,
      //     founds: newFounds
      //   }
      // } else {
      //   return state;
      // }
      return {
        ...state,
        founds: [action.payload, ...(state.founds ?? [])]
      }

    case dataActions.SET_QUESTIONS:
      return {
        ...state,
        questions: action.payload
      }

    case dataActions.RESET_FOUNDS:
      return {
        ...state,
        founds: [],
      }

    default:
      return state;
  }
}

