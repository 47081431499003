import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./AccountCreated.scss";
import { ReactComponent as Checkmark } from "./checkmark.svg";

const AccountCreated = () => {
  const navigate = useNavigate();

  // account has been created, navigate to dashboard after 1.5s
  useEffect(() => {
    const interval = setInterval(() => navigate("/subscription"), 1500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="account-layout">
      <Checkmark />
      <p>Your account has been created!</p>
    </div>
  );
};

export default AccountCreated;
