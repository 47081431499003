import { db } from './firebaseConfig';
import {
    collection,
    getDocs,
    query,
} from "@firebase/firestore";

export const trialAvailable = async (uid) => {
    const userRef = collection(db, `customers/${uid}/subscriptions`);
    const user = await getDocs(
        query(userRef)

    );
    const userDocs = [];


    for (const doc of user.docs) {
        if (doc.exists()) {
            userDocs.push(doc.data());
        }
    }
    return userDocs.length < 1;
}