// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getFirestore,
} from "@firebase/firestore";
import { getAuth } from 'firebase/auth';
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDVLnKvbOyNlE94QV7ubHv46K4XUEQ5yho",
  authDomain: "designmemo-deac8.firebaseapp.com",
  projectId: "designmemo-deac8",
  storageBucket: "designmemo-deac8.appspot.com",
  messagingSenderId: "163318719134",
  appId: "1:163318719134:web:edefd438a1dae794b7518f",
  measurementId: "G-8E8MTBC3YW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore();
export const auth = getAuth();
export const functionLocation = 'europe-west2';
export const functions = getFunctions(app, functionLocation);
export const storage = getStorage(app); 