import NoMatch from 'Pages/NoMatch/NoMatch';
import React from 'react'
import { useParams } from 'react-router-dom'
import MemoryGame from './MemoryGame/MemoryGame';

const GameChooser = () => {
  const { game } = useParams();

  switch (game) {
    case "memory":
      return <MemoryGame />;
    default:
      return <NoMatch />;
  }
}

export default GameChooser
