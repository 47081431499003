import React, { useState } from "react";
import Searchicon from "../../Pages/GameCreation/CardBack/Searchicon";
import Backarrow from "../../Assets/CardBacks/Backarrow";
import "./MemoryDisplay.scss";

export const DisplayCard = ({ cardStyle }) => {
  if (cardStyle.backgroundColor && cardStyle.backgroundColor !== "") {
    return (
      <div style={{ ...cardStyle }} alt="card-back" className="single-color">
        {cardStyle.backgroundText}
      </div>
    );
  } else {
    if (cardStyle.backgroundImage instanceof Blob) {
      return (
        <img
          src={URL.createObjectURL(cardStyle.backgroundImage)}
          alt="card-back"
          className="single-image"
        />
      );
    } else {
      return (
        <img
          src={cardStyle.backgroundImage}
          alt="card-back"
          className="single-image"
        />
      );
    }
  }
};

const MemoryDisplay = ({ cardStyle }) => {
  const [showSingle, setShowSingle] = useState(false);
  const memory = Array.from({ length: 12 }, (_, index) => {
    return <DisplayCard cardStyle={cardStyle} />;
  });

  return (
    <>
      {showSingle ? (
        <div className="memoryback-container">
          <div className="single-card">
            <DisplayCard cardStyle={cardStyle} />
          </div>
          <div
            className="search-icon"
            onClick={() => setShowSingle(!showSingle)}
          >
            <div className="arrow-container">
              <Backarrow />
              <p>Tillbaka till helvy</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="memoryback-container">
          <div className="display-card-holder">
            {memory.map((cardBack, index) => {
              return (
                <div className="display-card" key={index}>
                  {cardBack}
                </div>
              );
            })}
          </div>
          <div
            className="search-icon"
            onClick={() => setShowSingle(!showSingle)}
          >
            <Searchicon className="search-icon" />
          </div>
        </div>
      )}
    </>
  );
};

export default MemoryDisplay;
