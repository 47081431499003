import React, { useState } from 'react';
import './Editing.scss';
import { Form } from 'react-bootstrap';


const Field = ({ type, label, name, value, handleChange, touched, errors }) => {

  return (
    <Form.Group className="mb-3" controlId="formName">
      <Form.Label>{label}</Form.Label>
      <Form.Control
        type={type}
        name={name}
        value={value}
        onChange={handleChange}
        isInvalid={touched && errors}
      />
      <Form.Control.Feedback type="invalid">
        {errors}
      </Form.Control.Feedback>
    </Form.Group>
  )
}

export default Field
