import React, { useCallback, useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import "./ImageCrop.scss";
import getCroppedImg from "./cropImage";
import Slider from "rc-slider";

const ImageCrop = ({ image, onExit, updateImage, required }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImageShow, setCroppedImageShow] = useState(
    image instanceof Blob ? URL.createObjectURL(image) : image
  );

  const imageUrl = image instanceof Blob ? URL.createObjectURL(image) : image;

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageUrl, croppedAreaPixels, 0);
      setCroppedImageShow(croppedImage);

      let updateImageFile = await fetch(croppedImage)
        .then(async (r) => await r.blob())
        .then(
          (blobFile) => new File([blobFile], image.name, { type: image.type })
        );
      updateImage(updateImageFile);
    } catch (e) {
      console.log(e);
    }
  }, [croppedAreaPixels, imageUrl, updateImage, image]);

  return (
    <div className="cropper-layout">
      <div className="cropper-card">
        <div className="crop-container">
          <div className="slider-container">
            <Slider
              min={1}
              max={5}
              step={0.1}
              value={zoom}
              onChange={setZoom}
              vertical
            />
          </div>
          <div>
            <Cropper
              image={imageUrl}
              crop={crop}
              zoom={zoom}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              zoomWithScroll={true}
              onZoomChange={setZoom}
              maxZoom={5}
              showGrid={true}
              aspect={1}
              className="crop"
              zoomSpeed={0.5}
            />
          </div>
        </div>
        <button className="crop-button" onClick={showCroppedImage}>
          Spara
        </button>
        {required && (
          <button className="nocrop-button" onClick={() => onExit(false)}>
            Avbryt
          </button>
        )}
      </div>
    </div>
  );
};

export default ImageCrop;
