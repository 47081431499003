import React, { useState, useRef, useContext } from "react";
import { Modal, Button } from "react-bootstrap";

import { BsArrowBarRight } from "react-icons/bs";
import { DataContext } from "../../contexts/DataContext";



function CustomQuiz(props) {
  const { state } = useContext(DataContext);
  const [currentAnswer, setCurrentAnswer] = useState(null);
  const [currentQuestionIdx, setCurrentQuestionIdx] = useState(0);
  const [score, setScore] = useState(0); const formRef = useRef(null);

  const [answers, setAnswers] = useState([]);
  const [finished, setFinished] = useState(false);

  function answerQuestion() {
    if (currentAnswer !== null) {
      // save answer
      let answersCopy = JSON.parse(JSON.stringify(answers));
      answersCopy[currentQuestionIdx] = currentAnswer;
      setAnswers(answersCopy);

      // check if it is correct and update score
      if (state.questions[currentQuestionIdx].answer === currentAnswer)
        setScore(score + 1);

      // check quiz status and update current question
      if (currentQuestionIdx + 1 > state.questions.length - 1) {
        setFinished(true);
        setCurrentQuestionIdx(0);
      } else {
        setCurrentQuestionIdx(currentQuestionIdx + 1);
      }

      // reset for next question
      formRef.current.reset();
      setCurrentAnswer(null);
    }
  }

  function preventDefault(event) {
    event.preventDefault();
  }

  function onChangeAnswer(event) {
    if (event.target.checked) {
      setCurrentAnswer(event.target.value);
    }
  }

  function resetQuiz() {
    setAnswers([]);
    setCurrentAnswer(null);
    setCurrentQuestionIdx(0);
    setScore(0);
    setFinished(false);
  }

  function quitQuiz() {
    let quizResult = score;
    resetQuiz();
    props.onQuit(quizResult);
  }

  return (
    <Modal
      show={props.display}
      backdrop="static"
      keyboard={false}
      centered
      className="GameFinished"
    >
      <Modal.Body>
        {!finished ? (
          <form ref={formRef} onSubmit={preventDefault} id="quiz">
            <button className="buttonQuit" onClick={quitQuiz}>
              Quit
              <BsArrowBarRight />{" "}
            </button>
            <p className="welcomeMessage">
              Welcome to the quiz. The questions relate to the images in the
              memory game. Correct answers give bonus points that can be
              decisive when competing with others.
            </p>
            {state.questions.length > 0 &&
              <>
                <h4>
                  {currentQuestionIdx + 1}. {state.questions[currentQuestionIdx].title}
                </h4>
                <h2>
                  {state.questions[currentQuestionIdx].question}
                </h2>

                <ul id="questions">
                  <li key={`${currentQuestionIdx} 1`}>
                    <input
                      type="radio"
                      id="option1"
                      value="1"
                      name="option"
                      onChange={onChangeAnswer}
                    />
                    <label htmlFor="option1">
                      <p>1</p>
                      <p>{state.questions[currentQuestionIdx].options["1"]} </p>
                    </label>
                  </li>
                  <li key={`${currentQuestionIdx} X`}>
                    <input
                      type="radio"
                      id="optionX"
                      value="X"
                      name="option"
                      onChange={onChangeAnswer}
                    />
                    <label htmlFor="optionX">
                      <p>X</p>
                      <p>{state.questions[currentQuestionIdx].options["X"]}</p>{" "}
                    </label>
                  </li>
                  <li key={`${currentQuestionIdx} 2`}>
                    <input
                      type="radio"
                      id="option2"
                      value="2"
                      name="option"
                      onChange={onChangeAnswer}
                    />
                    <label htmlFor="option2">
                      <p>2</p>
                      <p>{state.questions[currentQuestionIdx].options["2"]}</p>{" "}
                    </label>
                  </li>
                </ul></>}
          </form>
        ) : (
          <section id="result">
            <p className="result-text">
              You got{" "}
              <span>
                {score}/{state.questions.length}
              </span>{" "}
              correct answers!
            </p>

            <h3>Questions with answers</h3>

            <ul>
              {state.questions.map((q, idx) => {
                return (
                  <li key={idx}>
                    <p className="question">{q.question} </p>
                    <div>
                      <p className="answerLabel">Correct answer is </p>
                      <p className="answer">
                        {q.answer}, {q.options[q.answer]}
                      </p>
                    </div>
                    <div>
                      <p className="answerLabel userAnswer">You answered</p>{" "}
                      <p className="answer userAnswer">
                        {answers[idx]}, {q.options[answers[idx]]}
                      </p>
                    </div>
                  </li>
                );
              })}
            </ul>

            <Button
              className="buttonQuit align-self-center"
              variant="info"
              onClick={quitQuiz}
            >
              Total result <BsArrowBarRight />{" "}
            </Button>
          </section>
        )}
      </Modal.Body>

      {!finished && (
        <Modal.Footer>
          <section id="controls">
            {!finished && (
              <Button
                //id="buttonAnswer"
                variant="info"
                disabled={currentAnswer === null}
                onClick={answerQuestion}
              >
                Next
              </Button>
            )}
          </section>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default CustomQuiz;
