import Dropdown from "react-bootstrap/Dropdown";
import "./CustomDropdown.scss";
import { Link } from "react-router-dom";

const CustomDropdown = ({
  options,
  optionsState,
  current,
  setCurrent,
  description,
  optionFont,
  variant,
}) => {
  return (
    <Dropdown variant={variant || "primary"}>
      <Dropdown.Toggle
        //className="custom-dropdown dropdown-toggle-custom"
        variant={variant || "primary"}
        //id="dropdown-basic"
      >
        {current || description}
        <span className="arrow-container">
          <i className="fas fa-caret-down" />
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu
        //className="custom-dropdown"
        variant={variant || "primary"}
      >
        {options.map((item, index) => (
          <Dropdown.Item
            key={index}
            disabled={optionsState ? optionsState[index] : false}
            variant={variant || "primary"}
            style={optionFont ? { [optionFont]: item } : {}}
            onClick={() => setCurrent(item)}
          >
            {item}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CustomDropdown;
