import "./MemoryGame.scss";
import React, { useState, useEffect, useRef, useReducer } from "react";
import SideNav from "Components/SideNav/SideNav";
import cards from "Assets/data/cards";
import fbUtilities from "apis/firebase";
import SideInfo from "Components/SideInfo/SideInfo";
import Quiz from "Components/Quiz/Quiz";
import { ArrowRight } from "react-bootstrap-icons";
import ShowRoom from "Components/ShowRoom/ShowRoom";
import Password from "Components/Password/Password";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { Spinner, Button } from "react-bootstrap";
import { dataReducer } from "contexts/DataReducer";
import { DataContext, initialState } from "contexts/DataContext";
import Modals from "Components/Modals";
import toast, { Toaster } from "react-hot-toast";
import { usePreloadImages } from "hooks";
import { getParsedTimer } from "helpers";
import AccountCreated from "Pages/AccountCreation/Created/AccountCreated";
import PlayableMemory from "Components/PlayableMemory/PlayableMemory";

let MAX_CARDS = 12;
//let MAX_CARDS = 1; //dev

function shuffle(a) {
  let j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}

function MemoryGame() {
  const [state, dispatch] = useReducer(dataReducer, initialState);
  const isLoading = usePreloadImages(cards);

  // Timer
  const [timer, setTimer] = useState(0);
  const [timerStarted, setTimerStarted] = useState(false); // Start paused

  const [hasSentFeedback, setHasSentFeedback] = useState(false);

  const [hasQuit, setHasQuit] = useState(false);
  const [hasSentResult, setHasSentResult] = useState(false);

  // Cardlist & picked cards
  const [cardList, setCardList] = useState([]);
  const [pairsPicked, setPairsPicked] = useState([]);
  const cardTimeout = useRef(null); // Uses for flipping back

  // Showroom objects
  const [selectedShowRoom, setSelectedShowroom] = useState(null);
  const [showShowroom, setShowShowroom] = useState(false);

  //Saved objects
  const [showObjects, setShowObjects] = useState(false);

  // Win modal
  const [winModal, setWinModal] = useState(false);

  // Feedback modal
  const [feedbackModal, setFeedbackModal] = useState(false);

  // Quiz modal
  const [quizModal, setQuizModal] = useState(false);
  const [quizResult, setQuizResult] = useState(null);

  // Welcome modal
  const [welcomeModal, setWelcomeModal] = useState(false);

  // Password
  //const [isPass, setIsPass] = useState(true);
  const [isPass, setIsPass] = useState(false); // dev

  //Pressing play on landing page
  const [isOnLanding, setIsOnLanding] = useState(true);

  // Score states
  const [matchedCards, setMatchedCards] = useState(0);
  const [movesCounter, setMovesCounter] = useState(0);

  // are-you-sure-Modal
  const [areYouSure, setAreYouSure] = useState(false);

  const resetGame = () => {
    setQuizResult(null);
    setTimerStarted(false);
    setTimer(0);
    setShowObjects(false);
    clearTimeout(cardTimeout.current);
    setMatchedCards(0);
    setMovesCounter(0);
    setPairsPicked([]);
    setWinModal(false);
    setSelectedShowroom(null);
    setHasQuit(false);
    setHasSentResult(false);
    setWelcomeModal(true);
    dispatch({ type: "RESET_FOUNDS" });
    // setWelcomeModal(false);// dev
    initAndShuffleCards();
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const questions = await fbUtilities.getQuestions();
        dispatch({ type: "SET_QUESTIONS", payload: questions });
        //dispatch({ type: 'SET_QUESTIONS', payload: [questions[0]] }); //dev
      } catch (e) {
        console.error(e);
      }
    };
    fetchQuestions();
  }, []);
  // Reload game, init on mount
  useEffect(() => {
    // Reset game
    resetGame();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateVhVariable);
    updateVhVariable();
  }, []);

  useEffect(() => {
    let interval = null;
    if (timerStarted) {
      interval = setInterval(() => {
        setTimer((timer) => timer + 1);
      }, 1000);
    } else if (!timerStarted && timer !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timerStarted, timer]);

  const updateVhVariable = () => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  const handleSavedObjectClick = (cardIde) => () => {
    setSelectedShowroom(cardIde);
    openShowRoom();
  };

  const handleFeedback = () => {
    if (hasSentFeedback) return;
    setFeedbackModal(true);
  };

  const handleQuit = (quizResults) => {
    handleFeedback();
    quitQuiz(quizResults);
  };

  const handleSendResultClick = (resultData) => {
    fbUtilities
      .sendResult(resultData)
      .then(() => toast.success("Thank you for participating!"));
    setHasSentResult(true);
  };
  const handleFeedbackSend = (rating, additional) => () => {
    if (hasSentFeedback) return;

    fbUtilities
      .sendFeedback(rating, additional)
      .then(() => toast.success("Thank you!"));
    setHasSentFeedback(true);
    setFeedbackModal(false);
  };

  const handleOnDeny = () => setAreYouSure(false);

  const handleOnAccept = () => {
    setAreYouSure(false);
    setHasQuit(true);
    openQuiz();
  };

  const handleShowObjectsClick = () => setShowObjects(true);

  // Close showroom
  const closeShowRoom = () => {
    // Start timer again
    if (!winModal && movesCounter > 0) setTimerStarted(true);

    setShowShowroom(false);
  };

  // Quit quiz
  const quitQuiz = (quizResults) => {
    setQuizResult(quizResults);
    setQuizModal(false);
    setWinModal(true);
  };

  // Play Quiz
  const playQuiz = () => {
    setQuizModal(true);
    setWinModal(false);
  };

  const openQuiz = () => {
    setTimerStarted(false);
    setQuizModal(true);
  };

  const handleSkipClick = () => {
    setAreYouSure(true);
  };

  // Open showroom
  const openShowRoom = () => {
    // pause timer and open
    setTimerStarted(false);
    setShowShowroom(true);
  };

  // Shuffle card function
  const initAndShuffleCards = () => {
    // Set boundary
    let cards_length = cards.length;
    let cardsList = [];

    if (MAX_CARDS > cards_length) MAX_CARDS = cards_length;

    // Create a list with all indexes & shuffle
    let card_indexes = shuffle(
      new Array(cards_length).fill(undefined).map((_, k) => k)
    );

    // Take MAX_CARDS from shuffled list
    for (let i = 0; i < MAX_CARDS; i++) {
      cardsList.push(card_indexes[i], card_indexes[i]);
    }

    cardsList = shuffle(cardsList);

    // info is used to uniquely identify a card for
    // storing in local storage
    setCardList(
      cardsList.map((e) => {
        return {
          id: e,
          info: { id: e, dirname: cards[e].dirname },
          flipped: false,
          paired: false,
        };
      })
    );
  };

  const updateShowRoom = (cardIde) => {
    setSelectedShowroom(cardIde);
  };

  const isPair = (card1, card2) => card1.ide === card2.ide;

  const checkForPair = (card1, card2) => {
    if (isPair(card1, card2)) {
      // Pair cards
      setPairCard(card1.idk);
      setPairCard(card2.idk);

      // Add to showroom
      updateShowRoom(card1.ide);

      const found = JSON.stringify(cardList[card1.idk].info);
      dispatch({ type: "UPDATE_FOUNDS", payload: found });
    }

    // Check points
    setTimeout(() => {
      if (isPair(card1, card2)) {
        if (matchedCards + 1 === MAX_CARDS) {
          setTimerStarted(false); // Turn off timer
          setWinModal(true);
        } else setMatchedCards(matchedCards + 1);
      }
    }, 325);
  };

  const checkForNoMatch = (card1, card2) => {
    if (!isPair(card1, card2)) {
      setFlipCard(card1.idk, false);
      setFlipCard(card2.idk, false);
    }
  };

  const setFlipCard = (idk, flip) => {
    let copyCardList = cardList;
    cardList[idk].flipped = flip;
    setCardList([...copyCardList]);
  };

  const setPairCard = (idk) => {
    let copyCardList = cardList;
    cardList[idk].paired = true;
    setCardList([...copyCardList]);
  };

  const onCardClick = (idk, ide) => {
    if (!timerStarted) {
      setTimerStarted(true);
    }
    let copyPairsPicked = pairsPicked;

    // Skip paired && flipped
    if (!cardList[idk].paired) {
      // Start timer
      if (!timerStarted) setTimerStarted(true);

      // Prevent same card being clicked twice
      if (copyPairsPicked.length === 1 && copyPairsPicked[0].idk === idk)
        return;

      // Check if there are two picked cards, if so, reset them
      if (copyPairsPicked.length === 2) {
        // Remove timeout & pairs picked
        clearTimeout(cardTimeout.current);
        checkForNoMatch(copyPairsPicked[0], copyPairsPicked[1]);

        copyPairsPicked = [];
      }

      // Append to picked cards
      copyPairsPicked.push({
        ide: ide,
        idk: idk,
      });
      setPairsPicked([...copyPairsPicked]);

      // Flip card
      setFlipCard(idk, true);

      // Check if a match
      if (copyPairsPicked.length === 2) {
        // Increase move counter
        setMovesCounter(movesCounter + 1);

        const [first, second] = [copyPairsPicked[0], copyPairsPicked[1]];

        checkForPair(first, second);

        // Change status of cards if paired
        cardTimeout.current = setTimeout(() => {
          // Flip back if no match
          checkForNoMatch(copyPairsPicked[0], copyPairsPicked[1]);
        }, 2000);
      }
    }
  };

  function logout() {
    resetGame();
    setIsPass(true);
  }

  return (
    <DataContext.Provider value={{ state, dispatch }}>
      <div className="app">
        <SwitchTransition mode="out-in">
          <CSSTransition timeout={325} key={isLoading} classNames="fade">
            {isLoading ? (
              <div className="loading">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <SwitchTransition mode="out-in">
                <CSSTransition
                  timeout={325}
                  key={isOnLanding}
                  classNames="fade"
                >
                  <div className="outerContainer">
                    {isPass ? (
                      <Password onSuccess={() => setIsPass(false)} />
                    ) : !isOnLanding ? (
                      /* <Landing onPlay={() => { setIsOnLanding(false); logAnalyticEvent('login') }} /> */
                      <AccountCreated />
                    ) : (
                      <div className="innerContainer">
                        <ShowRoom
                          onClose={closeShowRoom}
                          onSavedObjectClick={handleSavedObjectClick}
                          show={showShowroom}
                          id={selectedShowRoom}
                          timer={getParsedTimer(timer)}
                        />
                        <Toaster />
                        <Modals.GameFinished
                          timer={timer}
                          movesCounter={movesCounter}
                          logout={() => {
                            resetGame();
                            setIsOnLanding(true);
                          }}
                          display={winModal}
                          quizResult={quizResult}
                          onReset={resetGame}
                          onPlayQuiz={playQuiz}
                          onSendResult={handleSendResultClick}
                          sendResultIsDisabled={hasQuit || hasSentResult}
                        />
                        <Quiz display={quizModal} onQuit={handleQuit} />
                        <Modals.WelcomeModal
                          display={welcomeModal}
                          onStart={() => setWelcomeModal(false)}
                        />
                        <Modals.AreYouSureModal
                          display={areYouSure}
                          onAccept={handleOnAccept}
                          onDeny={handleOnDeny}
                        />
                        <Modals.FeedbackModal
                          display={feedbackModal}
                          onSkip={() => setFeedbackModal(false)}
                          onSend={handleFeedbackSend}
                        />
                        <main className="mainContainer">
                          <section className="nav">
                            <SideNav
                              timer={getParsedTimer(timer)}
                              timerStarted={timerStarted}
                              moves={movesCounter}
                              points={matchedCards}
                              onReset={resetGame}
                            />
                            <SideInfo
                              selectedShowRoom={selectedShowRoom}
                              onOpenShowroom={openShowRoom}
                              isShowroom={showShowroom}
                              showObjects={showObjects}
                              onShowObjectsClick={handleShowObjectsClick}
                              onSavedObjectClick={handleSavedObjectClick}
                            />
                          </section>
                          <div
                            className="contentWrapper"
                            onClick={() => setShowObjects(false)}
                          >
                            <Button
                              className="align-self-end mt-2 mr-2 border-0"
                              variant="outline-secondary"
                              onClick={handleSkipClick}
                            >
                              Skip to Quiz <ArrowRight />
                            </Button>
                            <PlayableMemory
                              {...{ onCardClick, pairsPicked, cardList }}
                            />
                          </div>
                        </main>
                      </div>
                    )}
                  </div>
                </CSSTransition>
              </SwitchTransition>
            )}
          </CSSTransition>
        </SwitchTransition>
      </div>
    </DataContext.Provider>
  );
}

export default MemoryGame;
