import React from "react";
import { ArrowLeft } from "react-bootstrap-icons";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import "./CustomShowRoom.scss"

function CustomShowRoom(props) {

  return (
    <div className="showRoomZIndex">
      <SwitchTransition mode="out-in">
        <CSSTransition
          timeout={300}
          key={props.show}
          classNames="fade">
          <div>
            {
              props.show ?
                <section className="CustomShowRoom">
                  <div id='nav'>
                    <button className="backToGame" onClick={props.onClose}>
                      <ArrowLeft className="arrowRight" size={24} /> Return to the game
                    </button>
                    <p id='clock'>Paused at: {props.timer}</p>
                  </div>
                  <article className="infoContainer">
                    <section className="motivInfo">
                      <header>
                        <div className="showroomText">
                          Showroom
                        </div>
                        <div >
                          <h1>{props.game.showroom[props.id].imageTitle}</h1>
                        </div>
                        <img src={props.game.gameImages[props.id]} className="motivImg" alt={props.game.showroom[props.id].name + " image"} />
                      </header>
                      <section className="description custom">
                        <h2 className="specificationsTitle">Description</h2>
                        <p>{props.game.showroom[props.id].imageDescription}</p>
                      </section>

                      <section className="overview">
                        <div className="overviewDescription">
                          <p>{props.game.showroom[props.id].imageDescription}</p>
                        </div>
                        {props.game.showroom[props.id].imageOption1 &&
                          <div>
                            <p>{props.game.showroom[props.id].imageOption1}</p>
                          </div>
                        }
                        {props.game.showroom[props.id].imageOption2 &&
                          <div >
                            <p>{props.game.showroom[props.id].imageOption2}</p>
                          </div>
                        }
                        {props.game.showroom[props.id].imageOption3 &&
                          <div >
                            <p>{props.game.showroom[props.id].imageOption3}</p>
                          </div>
                        }
                        {props.game.showroom[props.id].imageOption4 &&
                          <div >
                            <p>{props.game.showroom[props.id].imageOption4}</p>
                          </div>
                        }
                      </section>


                      {props.game.showroom[props.id].imageOption1 &&
                        <section className="optionalInfo">
                          <p>{props.game.showroom[props.id].imageOption1}</p>
                        </section>
                      }
                      {props.game.showroom[props.id].imageOption2 &&
                        <section className="optionalInfo">
                          <p>{props.game.showroom[props.id].imageOption2}</p>
                        </section>
                      }
                      {props.game.showroom[props.id].imageOption3 &&
                        <section className="optionalInfo">
                          <p>{props.game.showroom[props.id].imageOption3}</p>
                        </section>
                      }
                      {props.game.showroom[props.id].imageOption4 &&
                        <section className="optionalInfo">
                          <p>{props.game.showroom[props.id].imageOption4}</p>
                        </section>
                      }

                    </section>
                  </article>
                </section>
                : ""
            }</div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
}


export default CustomShowRoom;
