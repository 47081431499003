import "./Card.scss";
import React from "react";

import { CSSTransition, SwitchTransition } from "react-transition-group";
import cards from "../../Assets/data/cards";

function Card(props) {
  function controlCardback() {
    if (props.cardBack.backgroundImage !== "") {
      if (props.cardBack.backgroundImage instanceof Blob) {
        return (
          <img
            src={URL.createObjectURL(props.cardBack.backgroundImage)}
            alt="card-back"
          />
        );
      } else {
        return <img src={props.cardBack.backgroundImage} alt="card-back" />;
      }
    } else {
      return (
        <div style={{ ...props.cardBack }} alt="card-back">
          {props.cardBack.backgroundText}
        </div>
      );
    }
  }

  return (
    <div
      className={`Card ${props.paired ? "paired" : ""}`}
      onClick={() => props.onCardClick(props.idk, props.id)}
    >
      <SwitchTransition mode="out-in">
        <CSSTransition timeout={325} key={props.flipped} classNames="flip">
          {props.flipped ? (
            <img
              className="flipped"
              src={
                props.img instanceof Blob
                  ? URL.createObjectURL(props.img)
                  : props.img
              }
              //src={URL.createObjectURL(props.img)}
              alt={cards[props.id].name + " image"}
            />
          ) : (
            controlCardback()
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
}

export default Card;
