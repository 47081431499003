import { auth, db, functions } from "../firebaseConfig";
import {
  collection,
  getDocs,
  getDoc,
  query,
  orderBy,
  where,
  onSnapshot,
  addDoc
} from "@firebase/firestore";
import { httpsCallable } from 'firebase/functions';

/**
* Get all offered subscriptions and their prices
*
* @return Promise<
*   { title :: String
*   , prices :: [Intl. Formatted numbers]
*   , description: String
*   , expired: Bool
*   }
* >
*/

export const formatPrice = (price) => {
  return `${new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' })
    .format(price / 100)}/month`
}

export const getSubscriptionPlans = async () => {
  const querySnapshot = await getDocs(query(collection(db, 'products'), where("active", "==", true), orderBy("role", "desc")));

  const subscriptions = querySnapshot.docs.map(async doc => {

    const priceSnap = await getDocs(query(collection(db, `products/${doc.id}/prices`),
      where("active", "==", true),
      orderBy('unit_amount')));

    const stripePrice = priceSnap.docs[0];
    console.log(doc.data().metadata.live)
    const product = ({
      title: doc.data().name,
      description: doc.data().description,
      expired: false,
      price: stripePrice.data().unit_amount,
      priceId: stripePrice.id,
      type: stripePrice.data().type,
      live: doc.data().metadata.live,
    });
    return product;
  })
  const subs = Promise.all(subscriptions);

  return subs;
};

const subscribeFree = () => {
  console.log('Nice!');
  // cloud function to begin + end trial after x days
}

// Checkout handler
export const subscribe = async (price) => {
  if (price.type === 'one_time') {
    subscribeFree();
    return;
  }

  const selectedPrice = {
    price: price.priceId,
    quantity: 1
  }

  const checkoutSession = {
    line_items: [selectedPrice],
    success_url: window.location.origin,
    cancel_url: window.location.origin,
    billing_address_collection: 'required',
    allow_promotion_codes: true,
    trial_from_plan: true,
  };


  const uid = auth.currentUser.uid;

  const docRef = await addDoc(collection(db,
    `customers/${uid}/checkout_sessions/`),
    checkoutSession);

  // Wait for the CheckoutSession to get attached by the extension
  onSnapshot(docRef, (snap) => {
    const { error, url } = snap.data();
    if (error) {
      alert(`An error occured: ${error.message}`);
    }
    if (url) {
      window.location.assign(url); //ev utnyttja bibliotek
    }
  });

}

/**
 * Get the user's current subscription 
 * (there should only be one)
 */
export const currentSubscription = async () => {
  const uid = auth.currentUser.uid;

  const q = query(collection(db, `customers/${uid}/subscriptions`),
    where('status', 'in', ['trialing', 'active']))

  const qsnap = await getDocs(q);

  if (qsnap.empty) {
    return null;
  }
  const subscription = qsnap.docs[0].data();
  const priceData = (await getDoc(subscription.price)).data()

  return priceData;
}

/**
  * Visit billing portal
*/

export const visitBillingPortal = async () => {
  // Call billing portal function
  const createLink = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
  const { data } = await createLink({ returnUrl: window.location.origin });

  window.location.assign(data.url);
}

// Get custom claim role helper
export const getClaimRole = async () => {
  await auth.currentUser.getIdToken(true);
  const decodedToken = await auth.currentUser.getIdTokenResult();
  return decodedToken.claims.stripeRole;
}
