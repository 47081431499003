import PrivacyPolicy from 'Assets/data/PrivacyPolicy';
import React from 'react'
import { Modal } from "react-bootstrap";

const PolicyModal = ({ display, onClose }) => {
  return (
    <Modal
      show={display}
      backdrop="static"
      centered
      className="areYouSure"
    >
      <Modal.Header className="modal-header border-0" closeButton onHide={onClose}>
        <Modal.Title>
          Collecting Personal Information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PrivacyPolicy />
      </Modal.Body>
    </Modal >

  )
}

export default PolicyModal
