import { db } from './firebaseConfig';
import { doc, collection, updateDoc } from "firebase/firestore";


export const updateShowroom = async (game) => {
    try {

        const gameRef = doc(collection(db, "games"), game.id);

        await updateDoc(gameRef, {
            showroom: game.showroom
        })

    } catch (error) {
        console.log("Error creating game: ", error);
    }
}