import { getQuestions } from './questions';
import { sendFeedback } from './feedback';
import { getUserGames } from './getUserGames';
import { getCustomGame } from './getCustomGame';
import { deleteGame } from './deleteGame';
import { updateQuiz } from './updateQuiz';
import { updateShowroom } from './updateShowroom';
import { togglePlayable } from './togglePlayable';
import { trialAvailable } from './trialAvailable';
import { sendResult, getTodaysBestResult } from './results';

const fbUtilities = {
  getQuestions,
  sendFeedback,
  sendResult,
  getTodaysBestResult,
  getUserGames,
  getCustomGame,
  deleteGame,
  updateQuiz,
  updateShowroom,
  togglePlayable,
  trialAvailable,

}

export default fbUtilities;
